import { isProduction } from "app/settings"

// Dev
// Be sure to use zipcode 90064 when testing on dev
const AXLE_PARTNER_ID_DEV = "8fbc872d-efce-4008-9027-ed8eb5a0a373"
const AXLE_SERVICE_ID_BY_KIT_COUNT_DEV = {
  1: "d159fb66-e0ed-400e-a03d-ad6b080e19c4",
  2: "f2cf9530-169a-471c-a706-f3bb8c448670",
  3: "aa7f5eb8-dc0a-4354-a827-9c44428dec45",
}
const AXLE_IFRAME_URL_DEV = "https://patient.axlehealth-dev.com/book?"

// Production
const AXLE_PARTNER_ID_PROD = "4da68974-f90c-47b6-94c9-bd7e4e73f761"
const AXLE_SERVICE_ID_BY_KIT_COUNT_PROD = {
  1: "4a0ff74b-4099-4a65-81a3-a9790dd7144e",
  2: "b645920e-9981-4f53-999b-e056b6a0f0e9",
  3: "686b4a5f-09da-4428-b9f1-70e68657a2bb",
  4: "4a6abfc7-fede-46cc-bc79-f7b24ffe94c5",
  5: "39f4ddf7-e543-4940-9aa1-48fcb5594b3b",
}
const AXLE_IFRAME_URL_PROD = "https://patient.axlehealth.com/book?"

export const AXLE_PARTNER_ID = isProduction
  ? AXLE_PARTNER_ID_PROD
  : AXLE_PARTNER_ID_DEV
export const AXLE_SERVICE_ID_BY_KIT_COUNT = isProduction
  ? AXLE_SERVICE_ID_BY_KIT_COUNT_PROD
  : AXLE_SERVICE_ID_BY_KIT_COUNT_DEV
export const AXLE_IFRAME_URL = isProduction
  ? AXLE_IFRAME_URL_PROD
  : AXLE_IFRAME_URL_DEV

export const PHLEBOTOMY_BOOKING_FROM_LOCATIONS = {
  PATIENT_PORTAL_BANNER: "patient-portal-banner",
  PATIENT_PORTAL_INSTRUCTIONS: "patient-portal-instructions",
  PATIENT_CHECKOUT_SUCCESS: "patient-checkout-success",
  REMINDER_EMAIL: "reminder-email",
}
