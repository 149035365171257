import { makeStyles, Typography } from "@material-ui/core"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { selectOrderState } from "app/main/patient-checkout/store/selectors"
import { colors } from "app/theme"

const SaveCreditCardFee = () => {
  const order = useAppSelector(selectOrderState).order
  const creditCardFee = order?.credit_card_fee_price
  const [creditCardFeeEnabled] = useFeatureFlag(
    FeatureFlag.CreditCardFeeCheckoutEnabled
  )
  const classes = useStyles()

  if (creditCardFeeEnabled && creditCardFee && creditCardFee > 0) {
    return (
      <div className={classes.savePill}>
        <Typography className={classes.savePillText} component="span">
          Save ${creditCardFee}
        </Typography>
      </div>
    )
  } else {
    return <></>
  }
}

const useStyles = makeStyles((theme) => ({
  savePill: {
    backgroundColor: colors.blueGray[100],
    borderRadius: 9999, // makes the border radius equal to half of height
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  savePillText: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
  },
}))

export default SaveCreditCardFee
