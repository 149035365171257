import { useRef, useEffect, useState } from "react"

import { parsePhoneNumberFromString } from "libphonenumber-js"

import { useMediaQuery } from "@material-ui/core"
import * as Sentry from "@sentry/react"

import makeAppStyles from "app/utils/makeAppStyles"

import {
  AXLE_PARTNER_ID,
  AXLE_SERVICE_ID_BY_KIT_COUNT,
  AXLE_IFRAME_URL,
} from "./constants"
import { BookingData } from "./hooks/use-booking-data"

const useStyles = makeAppStyles((theme) => ({
  iframe: {
    marginTop: -100,
    overflow: "hidden",
  },
  iframeWrapper: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
}))

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export const ScheduleAxleBooking = ({
  bookingData,
}: {
  bookingData: BookingData
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))

  const { width } = useWindowDimensions()

  const iframeWidth = width >= 704 ? 704 : width - 20

  const phoneNumber = parsePhoneNumberFromString(
    bookingData.order.patient.phone_number,
    "US"
  )

  const kitCount = bookingData.order.kit_count
  let axleServiceId = AXLE_SERVICE_ID_BY_KIT_COUNT[kitCount]
  if (!axleServiceId) {
    axleServiceId = AXLE_SERVICE_ID_BY_KIT_COUNT[1]
    Sentry.captureMessage(
      `Could not find Axle service ID for kit count of ${kitCount}. Default to service ID for one kit.`,
      {
        level: "error",
        extra: { order_id: bookingData.order.id },
      }
    )
  }

  const iframeParameters = {
    partner_id: AXLE_PARTNER_ID,
    first_name: bookingData.order.patient.first_name,
    last_name: bookingData.order.patient.last_name,
    email: bookingData.order.patient.user.email,
    phone: phoneNumber ? phoneNumber.formatNational().replace(/\D/g, "") : "",
    hide_service_input: phoneNumber ? "true" : "false", // If unable to format phone number then don't hide input
    dob: bookingData.order.patient.birthday,
    sex: bookingData.order.patient.biological_sex,
    preset_services: axleServiceId,
    hide_patient_input: axleServiceId ? "true" : "false", // If we don't have the service ID don't hide input
    address1: bookingData.order.patient.default_shipping_address.street_1,
    address2: bookingData.order.patient.default_shipping_address.street_2
      ? bookingData.order.patient.default_shipping_address.street_2
      : "",
    zip_code: bookingData.order.patient.default_shipping_address.zipcode,
    hide_address_input: bookingData.order.patient.default_shipping_address
      .street_1
      ? "true"
      : "false",
    visit_meta: JSON.stringify({
      orderID: bookingData.order.id,
    }),
    embed: "false",
  }

  const url = AXLE_IFRAME_URL + new URLSearchParams(iframeParameters).toString()

  return (
    <div className={classes.iframeWrapper}>
      <iframe
        className={classes.iframe}
        ref={iframeRef}
        src={url}
        title="Schedule an Axle Blood Draw"
        width={iframeWidth}
        height={isMobile ? "1700" : "1950"}
      />
    </div>
  )
}
