import { useCallback } from "react"

import {
  PatientCheckoutFormData,
  PaymentMethodDetails,
  PaymentMethodType,
} from "app/types"

import useCreateAchPaymentMethod from "./use-create-ach-payment-method"
import useCreateCardPaymentMethod from "./use-create-card-payment-method"
import useCreateSplitItPaymentMethod from "./use-create-splitit-payment-method"

/**
 * This hook provides an async function for creating the payment method for the
 * given form data and returns the payment method details.
 *
 * @param token the checkout token string
 * @returns an async function that creates the payment method details
 */
export default function useCreatePaymentMethod(token: string) {
  const createCardPaymentMethod = useCreateCardPaymentMethod()
  const createSplitItPaymentMethod = useCreateSplitItPaymentMethod(token)
  const createAchPaymentMethod = useCreateAchPaymentMethod()
  return useCallback(
    async (
      formData: PatientCheckoutFormData
    ): Promise<PaymentMethodDetails | undefined> => {
      if (!formData.payment_method || !formData.payment_method.type) {
        return
      }

      const data = { type: formData.payment_method?.type }
      switch (formData.payment_method?.type) {
        case PaymentMethodType.CREDIT_CARD:
        case PaymentMethodType.DEBIT_CARD: {
          data["card"] = await createCardPaymentMethod(formData)
          break
        }
        case PaymentMethodType.SPLITIT: {
          data["splitit"] = await createSplitItPaymentMethod(formData)
          break
        }
        case PaymentMethodType.ACH: {
          data["ach"] = await createAchPaymentMethod(formData)
          break
        }
      }

      return data
    },
    [
      createCardPaymentMethod,
      createSplitItPaymentMethod,
      createAchPaymentMethod,
    ]
  )
}
