import { useFormContext } from "react-hook-form"

import {
  FormHelperText,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core"

import { ReactComponent as CheckCircleIcon } from "app/assets/images/circular-check-filled.svg"
import { FieldNames } from "app/main/patient-checkout/fields"
import useAchFields from "app/main/patient-checkout/hooks/use-ach-fields"
import PaymentMethodBase from "app/main/patient-checkout/sections/payment-methods/PaymentMethodBase"
import { colors, lightGray, navy, primaryColor } from "app/theme"
import { AchConnectionStatus, PaymentMethodType } from "app/types"

import SaveCreditCardFee from "./SaveCreditCardFee"

interface AchPaymentMethodProps {
  active: boolean
  paymentMethodError?: string
  disabled: boolean
}

const AchPaymentMethod = ({
  active,
  paymentMethodError,
  disabled,
}: AchPaymentMethodProps) => {
  const classes = useCommonPaymentStyles()
  const { expansion, connectButton, completeDiv, connectedText } = useStyles()

  const { status, openPlaid } = useAchFields()
  const { clearErrors } = useFormContext()

  return (
    <PaymentMethodBase
      active={active}
      className={classes.wrapper}
      header={<AchHeader />}
      title="Pay in Full via ACH"
      type={PaymentMethodType.ACH}
      disabled={disabled}
    >
      <div className={expansion}>
        {(status === AchConnectionStatus.INCOMPLETE ||
          status === AchConnectionStatus.NOT_STARTED) && (
          <Button
            size="medium"
            color="primary"
            onClick={() => {
              clearErrors(FieldNames.PAYMENT_METHOD_TYPE)
              openPlaid && openPlaid()
            }}
            className={connectButton}
            variant="contained"
          >
            {status === AchConnectionStatus.NOT_STARTED
              ? "Connect Bank Account"
              : "Finish Connecting Bank Account"}
          </Button>
        )}
        {status === AchConnectionStatus.COMPLETE && (
          <div className={completeDiv}>
            <CheckCircleIcon
              fill={colors.green[500]}
              width={21}
              height={21}
              viewBox="0 0 15 16"
            />
            <Typography component="span" className={connectedText}>
              Your bank will be used for payment.
            </Typography>
          </div>
        )}
        {paymentMethodError && (
          <FormHelperText error>{paymentMethodError}</FormHelperText>
        )}
      </div>
    </PaymentMethodBase>
  )
}

const AchHeader = () => {
  const classes = useCommonPaymentStyles()

  return (
    <div className={classes.headerWrapper}>
      <Typography
        className={classes.headerTitle}
        color="primary"
        component="span"
      >
        Pay in Full via ACH Bank Transfer
      </Typography>
      <SaveCreditCardFee />
    </div>
  )
}

export const useCommonPaymentStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottomWidth: "1px",
    borderColor: colors.gray[300],
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  headerSubtitle: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0.4),
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  expansion: {
    padding: "10px 15px",
    backgroundColor: lightGray,
  },
  connectButton: {
    backgroundColor: primaryColor,
    color: "white",
    padding: "5px 15px",
    borderRadius: 6,
  },
  errorText: {
    paddingTop: 5,
  },
  completeDiv: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  connectedText: {
    color: navy,
  },
}))

export default AchPaymentMethod
