export default class CheckoutPaymentMethodError extends Error {
  public type?: string

  constructor(message: string, type?: string) {
    super(message)
    this.name = "CheckoutPaymentMethodError"
    this.type = type

    Object.setPrototypeOf(this, CheckoutPaymentMethodError.prototype)
  }
}
