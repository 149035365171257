import { useCallback } from "react"

import { capitalize } from "lodash"

import * as Sentry from "@sentry/react"
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { StripeCardNumberElement } from "@stripe/stripe-js"

import {
  PatientCheckoutFormData,
  CardPaymentMethodDetails,
  PaymentMethodType,
} from "app/types"

import { CheckoutPaymentMethodError } from "../errors"

/**
 * Hook that generates a callback to creates a payment method using a card with Stripe
 * @returns Stripe payment method ID
 */
export default function useCreateCardPaymentMethod() {
  const stripe = useStripe()
  const elements = useElements()
  return useCallback(
    async (
      formData: PatientCheckoutFormData
    ): Promise<CardPaymentMethodDetails> => {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        const failedToInitializeError = new CheckoutPaymentMethodError(
          "Stripe failed to initialize",
          formData?.payment_method?.type || "stripe_card"
        )
        Sentry.captureException(failedToInitializeError)
        const customerFacingFailedToInitializeError =
          new CheckoutPaymentMethodError(
            "We're currently experiencing longer than normal loading times. Please reload the page or try using an incognito window while we work with our payment provider to resolve this. Thank you for your patience!",
            formData?.payment_method?.type || "stripe_card"
          )
        throw customerFacingFailedToInitializeError
      }

      if (!formData.payment_method) {
        throw new CheckoutPaymentMethodError(
          "Unable to process payment",
          "stripe_card"
        )
      }

      // Get a reference to a mounted CardNumberElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardNumberElement = elements.getElement(
        CardNumberElement
      ) as StripeCardNumberElement

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      })

      if (error) {
        // If it's a basic validation error, such as having not filled in the card number, then don't log to sentry.
        const stripeError = new CheckoutPaymentMethodError(
          error.message || "Failure to create Stripe payment method",
          formData?.payment_method?.type || "stripe_card"
        )

        if (error.type !== "validation_error") {
          Sentry.withScope(function (scope) {
            scope.setExtra("response", JSON.stringify(error))
            Sentry.captureException(stripeError)
          })
        }

        throw stripeError
      }

      // check that card type selected matches the number entered with "funding", which comes back from stripe.
      const isCreditNumber = paymentMethod?.card?.funding === "credit"
      const isCreditSelected =
        formData.payment_method.type === PaymentMethodType.CREDIT_CARD

      if (
        (isCreditSelected && !isCreditNumber) ||
        (!isCreditSelected && isCreditNumber)
      ) {
        throw new CheckoutPaymentMethodError(
          `Card type selected is ${
            formData.payment_method.type === PaymentMethodType.CREDIT_CARD
              ? "Credit"
              : "Debit"
          } but the card number entered is a ${capitalize(
            paymentMethod?.card?.funding
          )} card.`,
          formData?.payment_method?.type || "stripe_card"
        )
      }

      if (paymentMethod && paymentMethod.card) {
        return {
          last4: paymentMethod.card.last4,
          payment_method_id: paymentMethod.id,
          funding: paymentMethod.card.funding,
        }
      }

      const methodMissingError = new CheckoutPaymentMethodError(
        "Failure to create Stripe payment method",
        formData?.payment_method?.type || "stripe_card"
      )

      Sentry.captureException(methodMissingError)

      throw methodMissingError
    },
    [stripe, elements]
  )
}
