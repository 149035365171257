import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { first } from "lodash"

import useSplitItFlexFields from "app/main/patient-checkout/hooks/use-splitit-flex-fields"
import {
  PatientCheckoutFormData,
  PaymentMethodType,
  SplitItPaymentMethodDetails,
  SplitItInitiateResponse,
} from "app/types"
import retryOperation from "app/utils/retry-operation"

import { CheckoutPaymentMethodError } from "../errors"
import * as Actions from "../store/actions"

const INVALID_PAYMENT_PLAN_STATUS_RESPONSE = "Invalid Installment Plan Status"

export default function useCreateSplitItPaymentMethod(checkoutToken: string) {
  const dispatch = useDispatch()
  const { flexFields } = useSplitItFlexFields()
  return useCallback(
    async (
      formData: PatientCheckoutFormData
    ): Promise<SplitItPaymentMethodDetails> => {
      if (!formData.payment_method?.splitit) {
        throw new CheckoutPaymentMethodError(
          "Unable to process payment for SplitIt",
          PaymentMethodType.SPLITIT
        )
      }

      flexFields.setTermsAccepted(
        formData.payment_method.splitit.is_terms_accepted
      )

      /*
       * This uses an atypical retry mechanism to ensure that SplitIt has had time for validation to proceed.
       * SplitIt validates async from our form state, and that async validation does not trigger until the field is blurred.
       * This retry mechanism is meant to reduce the scenario where a user moves directly from the CVV to the submit button
       * in a very short period of time.
       */
      await retryOperation(async () => validateCreditCardFields(flexFields), {
        delay: 200,
        retries: 3,
      })

      const billing_address = formData.payment_method.splitit
        .is_billing_same_as_shipping
        ? formData.default_shipping_address
        : formData.payment_method.splitit.billing_address
      const updateRequest = {
        billing_address: billing_address,
        consumer_data: {
          full_name: `${formData.first_name} ${formData.last_name}`,
          email: formData.email,
          phone_number: formData.phone_number,
        },
      }

      let result: SplitItInitiateResponse
      try {
        result = (await dispatch(
          Actions.updateSplitIt(checkoutToken, updateRequest)
        )) as SplitItInitiateResponse
      } catch (error: any) {
        let errorMessage = error.response && error.response.data

        if (errorMessage === INVALID_PAYMENT_PLAN_STATUS_RESPONSE) {
          errorMessage =
            "Our session with SplitIt has expired. Please re-enter card details."

          // Generally, this occurs when the checkout screen was open for a long time and the plan has expired.
          // We re-initiate splitit to start with a fresh installment plan.
          dispatch(Actions.initiateSplitIt(checkoutToken))
        } else if (!errorMessage) {
          errorMessage =
            "Unable to process payment. Please try again, or use a different card"
        }

        throw new CheckoutPaymentMethodError(
          errorMessage,
          PaymentMethodType.SPLITIT
        )
      }

      if (!result.installment_plan?.active_card) {
        // no active card found, throw an error
        throw new CheckoutPaymentMethodError(
          "Unable to process payment. Please try again, or use a different card",
          PaymentMethodType.SPLITIT
        )
      }

      return {
        billing_address,
        last4: result.installment_plan.active_card.card_number.slice(-4),
      }
    },
    [checkoutToken, flexFields]
  )
}

/**
 * Validates the credit card fields for SplitIt are filled appropriately.
 *
 * @param flexFields the SplitIt FlexFields
 * @throws {CheckoutPaymentMethodError} if found to be invalid
 */
function validateCreditCardFields(flexFields) {
  const { invalidFields, isValid } = flexFields.getState().validationStatus
  if (isValid) {
    return
  }

  const firstError: any = first(invalidFields)
  if (firstError?.errors[0]) {
    throw new CheckoutPaymentMethodError(
      firstError.errors[0],
      PaymentMethodType.SPLITIT
    )
  } else {
    throw new CheckoutPaymentMethodError(
      "Unable to process credit card information. Please try again, or use a different card.",
      PaymentMethodType.SPLITIT
    )
  }
}
