import { FormHelperText, Grid, Typography, makeStyles } from "@material-ui/core"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js"

import PaymentMethodBase from "app/main/patient-checkout/sections/payment-methods/PaymentMethodBase"
import { lightGray } from "app/theme"
import { PaymentMethodType } from "app/types"

import { useCommonPaymentStyles } from "./AchPaymentMethod"
import SaveCreditCardFee from "./SaveCreditCardFee"

interface CardPaymentMethodProps {
  active: boolean
  paymentMethodError?: string
  disabled: boolean
  type: PaymentMethodType
}

const CardPaymentMethod = ({
  active,
  paymentMethodError,
  disabled,
  type,
}: CardPaymentMethodProps) => {
  const classes = useCommonPaymentStyles()

  return (
    <PaymentMethodBase
      active={active}
      className={classes.wrapper}
      header={<CardHeader type={type} />}
      title="Pay in full now"
      type={type}
      disabled={disabled}
    >
      {/* Stripe won't allow duplicate Elements to be created at once, so we only create the child elements if the option is selected. */}
      {active && <AddStripeCard paymentMethodError={paymentMethodError} />}
    </PaymentMethodBase>
  )
}

const CardHeader = ({ type }) => {
  const classes = useCommonPaymentStyles()
  const { flexColOnSmallScreen } = useStyles()

  return (
    <div className={classes.headerWrapper}>
      <div className={flexColOnSmallScreen}>
        <Typography
          className={classes.headerTitle}
          color="primary"
          component="span"
        >
          Pay in full now
        </Typography>{" "}
        <Typography
          className={classes.headerSubtitle}
          color="textPrimary"
          component="span"
        >
          {`(${mapCardPaymentMethodTypeToName(type)})`}
        </Typography>
      </div>
      {type === PaymentMethodType.DEBIT_CARD && <SaveCreditCardFee />}
    </div>
  )
}

const AddStripeCard = ({ paymentMethodError }) => {
  const inputClassNames = "rounded border-1 border-gray-300 p-2 bg-white"

  return (
    <div style={{ backgroundColor: lightGray }} className="px-6 py-3">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} data-testid="stripe-card-number">
          <CardNumberElement
            className={inputClassNames}
            options={{ placeholder: "Card Number" }}
          />
        </Grid>

        <Grid item xs={6} md={3} data-testid="stripe-card-exp">
          <CardExpiryElement
            className={inputClassNames}
            options={{ placeholder: "MM / YY" }}
          />
        </Grid>

        <Grid item xs={6} md={3} data-testid="stripe-card-cvc">
          <CardCvcElement
            className={inputClassNames}
            options={{ placeholder: "CVC" }}
          />
        </Grid>
      </Grid>

      {paymentMethodError && (
        <FormHelperText error>{paymentMethodError}</FormHelperText>
      )}
    </div>
  )
}

function mapCardPaymentMethodTypeToName(paymentMethod) {
  switch (paymentMethod) {
    case PaymentMethodType.CREDIT_CARD:
      return "Credit"
    case PaymentMethodType.DEBIT_CARD:
      return "Debit"
  }
}

const useStyles = makeStyles((theme) => ({
  flexColOnSmallScreen: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}))

export default CardPaymentMethod
